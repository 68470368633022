import React from 'react'
import './styles.less'

function Article({ children }) {
  return (
    <article className={`article`}>
      {children}
    </article>
  )
}

export default Article