import React from 'react'
import { Layout } from 'antd/lib/index'
import { Typography } from 'antd'
import './styles.less'

import SearchToggle from '../SearchToggle/SearchToggle'

const { Header } = Layout

const { Title } = Typography

function PageHeader({ children }) {
  return (
    <Header>
      <div>
        <Title level={2}>
          {children}
        </Title>
        <SearchToggle/>
      </div>
    </Header>
  )
}

export default PageHeader