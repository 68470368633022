import React, { useContext, memo } from 'react'
import { Button } from 'antd/lib/index'
import { desktopBreakpoint } from '../../constants'

import Context from '../../contexts/Search'
import useWindowWidth from '../../hooks/useWindowWidth'
import './styles.less'

function SearchToggle() {

  const { handleOpen } = useContext(Context)
  const width = useWindowWidth()
  const isTouch = width < desktopBreakpoint

  return (
    <Button className={`search-toggle`}
            size={`large`}
            type={`primary`}
            style={{ minWidth: `40px`, marginLeft: isTouch ? 0 : `7px` }}
            onClick={handleOpen} shape={`circle`}
            icon="search"/>
  )
}

export default memo(SearchToggle)