import React from 'react'
import { Layout } from 'antd/lib/index'
import './styles.less'
import Article from '../Article'

const { Content } = Layout


function PageContent({ children }) {
  return (
    <Content>
      <Article>
        {children}
      </Article>
    </Content>
  )
}

export default PageContent