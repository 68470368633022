import React, { Fragment } from 'react'
import { Typography, Divider } from 'antd'

import BannerImage from '../components/BannerImage'
import SEO from '../components/SEO'
import PageWrapper from '../components/PageWrapper'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Article from '../components/Article'
import EmailAddress from '../components/EmailAddress'
import Copyable from '../components/Copyable'
import { Registrar } from '../components/MapLocations/MapLocations'
import InfoBox from '../components/InfoBox/InfoBox'
import { AdmissionsCert } from '../components/DocumentExamples'
import ExternalLink from '../components/ExternalLink/ExternalLink'

const { Paragraph } = Typography

const IndexPage = () => (
  <Fragment>
    <SEO title="Home" keywords={[`students`, `tsinghua`, `guide`]}/>
    <PageWrapper>
      <PageHeader>About this guide</PageHeader>
      <PageContent>
        <Article>
          <BannerImage/>
          <Paragraph>Sometimes Tsinghua’s English documentation is hard to find. This site contains some useful stuff
            I’ve
            found while studying and working here as a graduate student. While it’s helpful if you know Chinese, these articles don’t require
            any prior
            knowledge of the language and are designed to be used as-is by graduate students.</Paragraph>
          <Paragraph>If you’d like to request a topic for an article or spot a mistake,
            please <EmailAddress
              address={`dushangcheng@tsinghua.edu.cn`}>email
              me</EmailAddress>.</Paragraph>
          <Divider>How to use this guide</Divider>
          <Paragraph>
            <ul style={{ marginBottom: `14px` }}>
              <li style={{ marginBottom: `8px` }}>Click <span className={'map-dialog__link'}>blue text</span> to view maps and directions (e.g. <Registrar>Office of the Registrar</Registrar>).
              </li>
              <li style={{ marginBottom: `8px` }}>Clicking <span className={'document-dialog__link'}
              >red text</span> shows example photos (e.g. <AdmissionsCert>Admissions Certificate</AdmissionsCert>).
              </li>
              <li style={{ marginBottom: `8px` }}>Click <span className={'external'}>purple text</span> to open a link in a new tab (e.g. <ExternalLink to={'https://www.tsinghua.edu.cn/en/News___Events.htm'}>Tsinghua News</ExternalLink>).
              </li>
              <li>Copy any piece of <Copyable>green text</Copyable> by clicking on it.
              </li>
            </ul>
          </Paragraph>
          <InfoBox color={`blue`} title={`Blue notification box`}>
            Throughout the articles, pay attention to these blue boxes. They contain important info that has been
            highlighted on purpose.</InfoBox>
          <InfoBox color={`red`} title={`Red warning box`}>
            The styling on these boxes is obnoxiously loud, because the info inside is always critical.
          </InfoBox>
          <Divider>About this guide</Divider>
          <Paragraph style={{ marginBottom: `28px` }}>
            This guide uses the Ant Design language made by <ExternalLink to={`https://xtech.antfin.com/`}>Ant Experience Technology</ExternalLink> in Beijing and <ExternalLink to={`https://www.gatsbyjs.com/`}>Gatsby</ExternalLink>, a free, open-source framework that helps developers build blazing fast websites and apps. If you found this guide to be useful, please share it with a friend.
          </Paragraph>
        </Article>
      </PageContent>
    </PageWrapper>
  </Fragment>
)

export default IndexPage
