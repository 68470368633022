import React, { memo } from 'react'
import './styles.less'

function PageWrapper({ children }) {
  return (
    <div className={`page-wrapper`}>
      {children}
    </div>
  )
}

export default memo(PageWrapper)